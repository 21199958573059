import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NewsRoomCard from './NewsRoomCard';

const RelatedPressReleases = ({ id, category }) => {
  const { newsroom } = useStaticQuery(query);

  const related = useMemo(
    () =>
      newsroom &&
      newsroom.nodes.length > 0 &&
      newsroom.nodes
        .filter(pr => pr.category?.name === category && pr.id !== id)
        .slice(0, 3)
        .map(pr => (
          <Col className="mb-4" md={4} key={pr.id}>
            <NewsRoomCard
              title={pr.title}
              excerpt={pr.excerpt}
              category={pr.category?.name}
              date={pr.datePublished}
              brandLogo={pr.brandLogo}
              newsImage={pr.featuredImage}
              slug={pr.slug}
            />
          </Col>
        )),
    [category, id, newsroom]
  );

  return (
    <Container>
      <Row className="d-flex justify-content-center">{related}</Row>
    </Container>
  );
};

export const query = graphql`
  {
    newsroom: allDatoCmsPressRelease {
      nodes {
        id
        title
        category: newsroomCategory {
          name
        }
        datePublished(formatString: "MMMM DD, YYYY")
        excerpt
        featuredImage {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          url
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        brandLogo {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          basename
          url
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
      }
    }
  }
`;

export default RelatedPressReleases;
